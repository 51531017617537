import * as React from 'react';
import './ArticleInformation.scss';
import SmileRating from '../../_Shared/SmileRaiting/SmileRating';
import SubscribeForm from '../../_Shared/SubscribeForm/SubscribeForm';
import MostPopular from '../MostPopular/MostPopular';
import Share from '../Share/Share';
import ScrollTopButton from '../../_Shared/ScrollTopButton/ScrollTopButton';
import Accordion from '../../_Shared/Accordion/Accordion';
import TableContent from '../../_Shared/TableContent/TableContent';
import usePortableText from '../../../hooks/usePortableText';
import { ArticleInformationData } from './types';

const ArticleInformation = ({
  articleBody,
  articleLinks,
  articleFaq,
  articleId,
  mostPopular,
}: ArticleInformationData) => {
  const { renderPortableText } = usePortableText();

  return (
    <div className="articleContent">
      <div className="articleContent__container">
        <div>
          <div className="articleContent__leftSide">
            <MostPopular mostPopular={mostPopular} />
            <Share />
          </div>
          <div className="tableContent__leftSide">
            <TableContent data={articleLinks} />
          </div>
        </div>
        <div className="articleContent__rightSide">
          {renderPortableText(articleBody)}
          <ScrollTopButton />
          <Accordion data={articleFaq} />
          <SmileRating id={articleId} />
          <SubscribeForm />
        </div>
      </div>
    </div>
  );
};

export default ArticleInformation;
