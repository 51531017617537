import React from 'react';
import { Link } from 'gatsby';
import './MostPopular.scss';
import { MostPopularData } from '../ArticleInformation/types';
import ROUTES from '../../../constants/routes';

interface MostPopularProps {
  mostPopular: MostPopularData[];
  header?: string;
}

const MostPopular = ({ mostPopular, header = 'Most Popular' }: MostPopularProps) => {
  const renderMostPopular = () => {
    return (
      <>
        {mostPopular
          .sort(() => Math.random() - 0.5)
          .slice(0, 3)
          .map(({ title, id, slug: { current } }, index: number) => {
            return (
              <Link id={id} to={ROUTES.BLOG + current} key={index}>
                {title}
              </Link>
            );
          })}
      </>
    );
  };

  return (
    <>
      <span>{header}</span>
      <div className="writer__popular">{renderMostPopular()}</div>
    </>
  );
};

export default MostPopular;
