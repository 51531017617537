import React, { useEffect, useState } from 'react';
import './SmileRating.scss';
import { smileRatingData } from './defaultData';
import { useFirebaseDatabase } from '../../../hooks/useFirebaseDatabase';
import { signInAnonymously } from 'firebase/auth';
import { auth } from '../../../modules/config';

interface SmileRatingProps {
  [key: string]: string;
}

const SmileRating = ({ id }: SmileRatingProps) => {
  const { setRating, getRating, setId } = useFirebaseDatabase();
  const [ratingData, setRatingData] = useState<any>();
  const [anonymousId, setAnonymousId] = useState<any>();
  const message = () => alert('Thank you for voting');
  const signIn = () => {
    signInAnonymously(auth).then((data) => setId(data.user.uid));
  };

  useEffect(() => {
    signInAnonymously(auth).then((data) => setAnonymousId(data.user.uid));
    getRating(id).then((data) => setRatingData(data));
  }, []);

  const result = ratingData?.data.vote.some((item: any) => {
    return item === anonymousId;
  });

  const handleChange = (rating: number, userId: string) => {
    setRating(id, [...ratingData.data.rating, rating + 1], [...ratingData.data.vote, userId])
      .then(signIn)
      .then(message);
  };

  return (
    <div className="rating">
      {!result ? <h6>This Article Was helpful?</h6> : <h6>Thank you for voting</h6>}
      <div className="rating__smile">
        {smileRatingData.map((smileIcon, index) => {
          return (
            <div className={!result ? 'rating__smile_reaction' : 'smile_disabled'} key={index}>
              <img
                loading="lazy"
                onClick={!result ? () => handleChange(index, anonymousId) : undefined}
                src={smileIcon}
                alt="rating"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SmileRating;
