import React from 'react';
import './Writer.scss';
import { navigate } from 'gatsby';
import { WriterProps } from './types';
import ROUTES from '../../../constants/routes';

const Writer = ({ authorFullName, authorAvatar, slug: { current } }: WriterProps) => {
  return (
    <div className="writer">
      <div className="head_item" onClick={() => navigate(ROUTES.AUTHOR + current)}>
        <img loading="lazy" width="50px" height="50px" src={authorAvatar.asset.url} alt="Writer photo" />
        <span>{authorFullName}</span>
      </div>
    </div>
  );
};

export default Writer;
