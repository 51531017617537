import * as React from 'react';
import ArticleContentInfo from './ArticleContentInfo/ArticleContentInfo';
import { ArticleContentProps } from './types';
import { monthsArray } from '../../constants/months';
import ArticleDescription from './ArticleDescription/ArticleDescription';
import ArticleInformation from './ArticleInformation/ArticleInformation';
import RelatedArticles from './ArticleInformation/RelatedArticles/RelatedArticles';
import { countArticleReadingTime } from '../../utils/utilsReadingTime';

const ArticleContent = ({ data }: ArticleContentProps) => {
  const articleBody = data.sanityBlogArticle._rawContent;
  const articleLinks = data.sanityBlogArticle.navigationLinks.link;
  const id = data.sanityBlogArticle.id;
  const { authors, title, image, faqMarkup } = data.sanityBlogArticle;
  const category = data.sanityBlogArticle.categories[0].title;
  const categoryId = data.sanityBlogArticle.categories[0].sanityId;
  const relatedArticles = data.sanityBlogArticle.relatedArticles.relatedArticles;
  const articleReleaseDate = data.sanityBlogArticle.publicationDate;
  const mostPopular = data.allSanityBlogArticle.nodes;
  const arrayOfSymbols: number[] = [];

  const getNewDateFormat = (date: string | undefined) => {
    if (typeof date === 'string') {
      const milliseconds = Date.parse(date);
      const newDate = new Date(milliseconds);
      const [day, month, year] = [newDate.getDate(), newDate.getMonth(), newDate.getFullYear()];

      return `${day} of ${monthsArray[month]} ${year}`;
    }
  };

  countArticleReadingTime(articleBody, arrayOfSymbols);
  const countOfSymbols = arrayOfSymbols.reduce((acc, element) => acc + element, 0);
  const readingTime = +(countOfSymbols / 1200).toFixed(0);

  return (
    <section className="article">
      <ArticleContentInfo
        title={title}
        category={category}
        readingTime={readingTime}
        mainImage={image}
        authorFullName={authors[0].fullName}
        authorAvatar={authors[0].avatar}
        slug={authors[0].slug}
        articleId={id}
        categoryId={categoryId}
      />
      <ArticleDescription
        articleCreatedAt={getNewDateFormat(articleReleaseDate)}
        readingTime={readingTime}
        articleId={id}
      />
      <ArticleInformation
        articleFaq={faqMarkup}
        articleBody={articleBody}
        articleLinks={articleLinks}
        articleId={id}
        mostPopular={mostPopular}
      />
      <RelatedArticles data={relatedArticles} />
    </section>
  );
};

export default ArticleContent;
