import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import './TableContent.scss';

interface TableContentProps {
  data: string[];
}

const TableContent = ({ data }: TableContentProps) => {
  let prevLink: Element, currLink: Element;

  const scrollHandler = () => {
    const headings = Array.from(document.querySelectorAll('.heading.h2'));
    const headingsLinks = Array.from(document.querySelectorAll('.heading-link'));
    const topPositionHeading = headings.find(
      (elem) => elem?.getBoundingClientRect().top < 250 && elem?.getBoundingClientRect().top > 0
    ) as HTMLElement;

    if (topPositionHeading && prevLink) {
      prevLink.classList.remove('highlighted-link');
    }

    if (topPositionHeading) {
      currLink = headingsLinks[headings.indexOf(topPositionHeading)];
      currLink?.classList?.add('highlighted-link');
      prevLink = currLink;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <>
      <span>Table of Content</span>
      <div className="tableContent">
        <div className="tableContent__progressBar" />
        <div className="tableContent__links">
          {data.map((title: string, index: number) => {
            return (
              <Link className="heading-link" to={'#' + title} key={index}>
                {title}
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TableContent;
