import * as React from 'react';
import './RelatedArticles.scss';
import { BlogCard } from '../../../_Shared/BlogCard/BlogCard';
import { CARD_STYLES } from '../../../_Shared/BlogCard/BlogCard';
import { RelatedArticlesProps } from './types';
import { countArticleReadingTime } from '../../../../utils/utilsReadingTime';

const RelatedArticles = ({ data }: RelatedArticlesProps) => {
  return (
    <div className="relatedArticles">
      <div className="relatedArticles__container">
        <h2 className="relatedArticles__title">Related Articles</h2>
        <div className="relatedArticles__cards">
          {data.map(({ image, title, _rawContent, id, slug: { current }, categories }, index: number) => {
            const category = categories[0].title;
            const cardImage = image.asset.url;
            const arrayOfSymbols: number[] = [];

            countArticleReadingTime(_rawContent, arrayOfSymbols);
            const countOfSymbols = arrayOfSymbols.reduce((acc, element) => acc + element, 0);
            const readingTime = +(countOfSymbols / 1200).toFixed(0);

            return (
              <BlogCard
                slug={current}
                key={index}
                time={readingTime}
                categories={category}
                description={title}
                image={cardImage}
                className={CARD_STYLES.CARD_STYLES_SMALL}
                articleId={id}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RelatedArticles;
