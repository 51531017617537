import * as React from 'react';
import PageLayout from '../../components/_Shared/PageLayout/PageLayout';
import '../../../node_modules/normalize.css/normalize.css';
import '../../globalStyles/_fonts.scss';
import '../../globalStyles/_styles.scss';
import ArticleContent from '../../components/Articles';
import { graphql } from 'gatsby';
import { ArticleContentProps } from '../../components/Articles/types';

export const query = graphql`
  query MyArticle($id: String) {
    sanityBlogArticle(id: { eq: $id }) {
      title
      summary
      id
      publicationDate
      seo {
        title
        description
      }
      navigationLinks {
        link
      }
      categories {
        sanityId
        title
      }
      image {
        asset {
          url
        }
      }
      slug {
        current
      }
      authors {
        fullName
        position
        slug {
          current
        }
        avatar {
          asset {
            url
          }
        }
      }
      faqMarkup {
        question
        _rawAnswers
      }
      relatedArticles {
        relatedArticles {
          id
          categories {
            title
          }
          slug {
            current
          }

          title
          time
          summary
          image {
            asset {
              url
            }
          }
          _rawContent
        }
      }
      mostPopularArticles {
        relatedArticles {
          title
          slug {
            current
          }
        }
      }
      _createdAt(fromNow: true)
      _rawContent
    }
    allSanityBlogArticle {
      nodes {
        id
        title
        slug {
          current
        }
      }
    }
    allSanityFooter {
      nodes {
        title
        footerRow {
          title
          link
        }
      }
    }
  }
`;

const Article = ({ data }: ArticleContentProps) => {
  const { title, description } = data.sanityBlogArticle.seo;
  const articleContentData = data.sanityBlogArticle;
  const { allSanityFooter } = data;

  return (
    <PageLayout footerData={allSanityFooter} markData={articleContentData} title={title} description={description}>
      <ArticleContent data={data} />
    </PageLayout>
  );
};

export default Article;
