import React from 'react';
import SVG_ICONS_ARTICLE from '../../../images/article/svg';
import './ScrollTopButton.scss';

const ScrollTopButton = () => {
  return (
    <a href="#article_top" className="scrollToTop">
      <img loading="lazy" src={SVG_ICONS_ARTICLE.ROCKET} alt="Scroll top image" />
    </a>
  );
};

export default ScrollTopButton;
