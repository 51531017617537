import * as React from 'react';
import './ArticleDescription.scss';
import SVG_ICONS_ARTICLE from '../../../images/article/svg';
import SVG_ICONS_SHARED from '../../../images/_shared/svg';
import StarRating from '../../_Shared/StarRating/StarRating';
import { ArticleDescriptionData } from './types';

const ArticleDescription = ({ readingTime, articleCreatedAt, articleId }: ArticleDescriptionData) => {
  return (
    <div className="articleDescription">
      <div className="articleContainer__description">
        <div className="articleDescription__content">
          <img loading="lazy" src={SVG_ICONS_ARTICLE.CALENDAR} alt="calendar icon" />
          <span>{articleCreatedAt}</span>
        </div>
        <div className="articleDescription__content">
          <img loading="lazy" src={SVG_ICONS_SHARED.CLOCK} alt="reading time icon" />
          <span>{readingTime} min</span>
        </div>
        <div className="articleDescription__content">
          <StarRating id={articleId} />
        </div>
      </div>
    </div>
  );
};

export default ArticleDescription;
