import * as React from 'react';
import './ArticleContentInfo.scss';
import '../../../images/article/svg';
import { Budge } from '../../_Shared/Budge/Budge';
import SVG_ICONS_SHARED from '../../../images/_shared/svg';
import Writer from '../../_Shared/Writer/Writer';
import ROUTES from '../../../constants/routes';
import BreadCrumbs from '../../_Shared/Breadcrumbs/Breadcrumbs';
import { ArticleContentInfoData } from './types';

const ArticleContentInfo = ({
  title,
  category,
  readingTime,
  articleId,
  categoryId,
  mainImage: {
    asset: { url },
  },
  authorFullName,
  authorAvatar,
  slug,
}: ArticleContentInfoData) => {
  return (
    <div className="articleHeader" id="article_top">
      <div className="articleHeader__container">
        <div className="articleHeader__contentWrapper">
          <div className="article__breadCrumbs">
            <BreadCrumbs />
          </div>
          <div className="article__info">
            <div className="articleHeader__image">
              <img className="spiralImage" width="300px" height="300px" src={url} alt="Main shape image" />
            </div>
            <div className="articleHeader__text">
              <Budge
                categoryId={categoryId}
                id={articleId}
                category={category}
                navigation={ROUTES.BLOG}
                time={`${readingTime} min`}
                image={SVG_ICONS_SHARED.CLOCK}
              />
              <h1>{title}</h1>
              <Writer slug={slug} authorFullName={authorFullName} authorAvatar={authorAvatar} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleContentInfo;
