import spiral from './svg/spiral_image.svg';
import figure from './svg/figure.svg';
import figure2 from './svg/figure_2.svg';
import figure3 from './svg/figure_3.svg';
import calendar from './svg/calendar.svg';
import rating from './svg/rating.svg';
import colorCircle from './svg/color_circle.svg';
import folder from './svg/folder.svg';
import playIcon from './svg/play_icon.svg';
import rocket from './svg/rocket.svg';

const SVG_ICONS_ARTICLE = {
  SPIRAL: spiral,
  FIGURE: figure,
  FIGURE_2: figure2,
  FIGURE_3: figure3,
  CALENDAR: calendar,
  RATING: rating,
  COLOR_CIRCLE: colorCircle,
  FOLDER: folder,
  PLAY_ICON: playIcon,
  ROCKET: rocket,
};

export default SVG_ICONS_ARTICLE;
