import React from 'react';
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import SVG_ICONS_SHARED from '../../../images/_shared/svg';
import './Share.scss';

interface ShareProps {
  url?: string;
}

const Share = ({ url = 'https://lunka.tech/' }: ShareProps) => {
  return (
    <>
      <span>Share On</span>
      <div className="writer__share">
        <LinkedinShareButton url={url} className="socialIcon">
          <img loading="lazy" src={SVG_ICONS_SHARED.LINKEDIN} alt="linkedin social media  icon" />
        </LinkedinShareButton>
        <FacebookShareButton url={url} className="socialIcon">
          <img loading="lazy" src={SVG_ICONS_SHARED.FACEBOOK} alt="facebook social media icon" />
        </FacebookShareButton>
        <TwitterShareButton url={url} className="socialIcon">
          <img loading="lazy" src={SVG_ICONS_SHARED.TWITTER} alt="twitter social media icon" />
        </TwitterShareButton>
        <EmailShareButton url={url} className="socialIcon">
          <img loading="lazy" src={SVG_ICONS_SHARED.LINK} alt="email icon" />
        </EmailShareButton>
      </div>
    </>
  );
};

export default Share;
